<script>
  export let bidId;
  let content = '';
  let newComments = [];
  let errors = {};
  let count = 0;
  let isLoading = false;

  function sendForm() {
    isLoading = true;

    const data = { comment: { content: content }, authenticity_token: Verifier.authencityToken };
    const url = `/bids/${bidId}/comments`;

    fetch(url, { method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-type': 'application/json' },
        body: JSON.stringify(data)})
      .then(async response => {
        if(response.ok) {
          const comment = await response.json();
          newComments = [comment, ...newComments];

          errors = {};
          content = '';
        } else {
          errors = await response.json();
        }
        isLoading = false;
      });
  }
</script>

<form action="bids/{bidId}/comments" method="post" class="mb-3" id="comment_form">
  <textarea
    bind:value="{content}"
    name="comment[content]"
    class="form-control mb-2 {errors.content ? 'is-invalid' : ''}"
    ></textarea>

  <button
    disabled={isLoading}
    on:click|preventDefault={sendForm}
    class="btn btn-outline-success btn-block col-12"
    type="submit"
    >
    Добавить комментарий
  </button>
</form>

<div id="comments_list">
  {#each newComments as comment}
    <p>
      <strong>{comment.author_name}</strong>
      <em class="">{comment.created_at_formatted}</em><br>
      {comment.content}
    </p>
  {/each}
</div>
