import IMask from 'imask';

window.addEventListener('load', (event) => {
  if (document.querySelector("#bid_form")) {
    let phoneInputs = document.querySelectorAll('[role="phone-input"]');
    phoneInputs.forEach((input) => {
      IMask(input, {
        mask: '{8} (000) 000-00-00',
        lazy: false,            // make placeholder always visible
        // placeholderChar: '_'    // defaults to '_'
      })
    })
  }
})
