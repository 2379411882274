export default class ProtocolConditionMimic {
  constructor(beginEl, endEl) {
    this.beginEl = beginEl;
    this.endEl = endEl;

    let isEndMimicable = !beginEl.value || beginEl.value === endEl.value;

    if (beginEl.value && !endEl.value) {
      endEl.value = beginEl.value;
      isEndMimicable = true;
    }

    beginEl.addEventListener('input', (e) => {
      if (isEndMimicable) {
        endEl.value = e.currentTarget.value;
      }
    });

    ['blur', 'input'].forEach((event) => {
      endEl.addEventListener(event, (e) => {
        if (isEndMimicable && (beginEl.value || endEl.value)) {
          isEndMimicable = false;
        }
      });
    })
  }
}
