<script>
  import BigNumber from "bignumber.js";

  const BTN_DELTA = 0.125;

  export let measurement;
  export let volume;
  export let type;

  function onClick() {
    const second = volume * (BTN_DELTA / 100);

    let first = new BigNumber(measurement);
    if (first == 0 || isNaN(first)) {
      first = new BigNumber((volume * BTN_DELTA) / 100);
    }

    measurement = type === "inc" ? first.plus(second) : first.minus(second);
  }
</script>

<button
  class="btn btn-outline-secondary btn-lg"
  on:click|preventDefault={onClick}
>
  <slot />
</button>
