import MeasurementEquipmentSelector from 'src/protocol_form/measurements_equipment_selectors';
import ProtocolConditionMimic from 'src/protocol_form/conditions';
import { attr } from 'svelte/internal';

window.addEventListener('load', function () {
  let block = document.querySelector('#protocol_form');
  if (block) {
    new MeasurementEquipmentSelector(block);

    const attrNames = ['air_temperature', 'water_temperature', 'atmosphere_pressure', 'humidity'];
    attrNames.forEach((name) => {
      new ProtocolConditionMimic(
        block.querySelector(`#protocol_${name}_begin`),
        block.querySelector(`#protocol_${name}_end`)
      );
    })
  }
})
