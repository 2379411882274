window.addEventListener("load", () => {
  const page = document.querySelector('#zones-page');

  if (page) {
    const isEditor = page.dataset['editor'] === 'true';

    require('src/yamap/panel');
    ymaps.ready(['Panel']).then(init);

    const stateStore = {};
    window.stateStore = stateStore;

    stateStore.colors = {
      "#03a8a0": "Бирюзовый",
      "#21409a": "Синий",
      "#04adff": "Голубой",
      "#039c4b": "Зеленый",
      "#fedf17": "Желтый",
      "#f16623": "Оранжевый",
      "#f44546": "Красный",
      "#ff0984": "Розовый",
    };

    stateStore.getPolygons = function() {
      let polygons = []
      this.map.geoObjects.each(function(o) {
        if (o.geometry.getType() === 'Polygon') {
          polygons.push(o)
        }
      });
      return polygons;
    };

    stateStore.setActivePolygon = function(polygon) {
      this._activePolygon = polygon;
      this.getPolygons().forEach(function(p) {
        setPolygonState(p, 'solid')
      })
      return this._activePolygon;
    };

    stateStore.getActivePolygon = function() {
      return this._activePolygon;
    };

    stateStore.randomColor = function () {
      const arr = Object.entries(this.colors);
      const i = Math.floor(Math.random() * arr.length);
      return arr[i][0];
    }

    const dataEl = document.querySelector('[name="zone_page_data"]');

    stateStore.verifiers = JSON.parse(dataEl.dataset['verifiers']);
    stateStore.zones = JSON.parse(dataEl.dataset['zones']);
    stateStore.city = JSON.parse(dataEl.dataset['city']);

    function renderNewZoneBtn(map) {
      const POS = { top: 12, right: 12 }

      const btn = new ymaps.control.Button({
        data: { content: "Добавить зону" },
        options: { selectOnClick: false, maxWidth: 150 } });

      map.controls.add(btn, { float: 'none', position: { top: POS.top, right: POS.right }});

      return btn;
    };

    function createPolygon(map, zone) {
      const color = zone.color;
      let coordinates = [];
      if (zone.points) { coordinates.push(zone.points) }

      const polygon = new ymaps.Polygon(coordinates, { zone: { id: zone.id } }, {
        editorDrawingCursor: "crosshair",
        fillColor: color, strokeColor: color,
        editorMenuManager: function (t) {
          return t.filter(function (t) { return "addInterior" !== t.id });
        }
      });

      map.geoObjects.add(polygon);

      if (isEditor) {
        polygon.editor.startEditing();
      };

      setPolygonState(polygon, 'solid');

      polygon.geometry.events.add('change', function (e) {
        showZoneForm(map, polygon);

        stateStore.setActivePolygon(polygon);
        detectIntersections(map, polygon);
      });


      polygon.events.add('click', function (e) {
        stateStore.setActivePolygon(polygon);
        showZoneForm(map, polygon);
      });

      return polygon
    }

    function detectIntersections(map, polygon) {
      setPolygonState(polygon, 'solid');

      stateStore.getPolygons().forEach(function(otherPolygon) {
        if (polygon.geometry.getCoordinates()[0].length > 2 &&
            otherPolygon.geometry.getCoordinates()[0].length > 2 &&
            otherPolygon !== polygon) {
          const query = ymaps.geoQuery(polygon);

          const result = query.searchIntersect(otherPolygon);
          if (result.getLength() > 0) {
            setPolygonState(polygon, 'intersection')
          }
        }
      });
    }

    function newZoneBtnClicked(map) {
      const zone = { color: stateStore.randomColor(), unsaved: true };
      const polygon = createPolygon(map, zone);
      stateStore.setActivePolygon(polygon);

      const polyId = 'new_' + Math.floor(Math.random() * 1000000000);
      stateStore.zones[polyId] = zone;
      polygon.properties.set('polyId', polyId);

      polygon.editor.startDrawing();
      showZoneForm(map, polygon);
      hideNewZoneBtn(map);
      showPanel(map);
    }

    function showZoneForm(map, polygon) {
      const polyId = polygon.properties.get('polyId');

      if (map.panel.visible && map.panel.polyId === polyId) { return }
      map.panel.polyId = polyId;
      const zone = stateStore.zones[polyId];

      if (isEditor) {
        const form = renderZoneForm(zone);
        map.panel.setContent(form);

        form.addEventListener('submit', saveAndCloseZoneForm)
        form.querySelector('button[role="destroy"]').addEventListener('click', destroyBtnClicked)

        form.querySelector('[name="color"]').addEventListener('change', colorChanged)
      } else {
        const verifier = stateStore.verifiers.find((v) => v[0] === String(zone.verifier_id));

        let html = '';

        if (zone.name) { html += `<strong>${zone.name}</strong><br />`};
        html += verifier[1];

        map.panel.setContent(html);
      }

      function saveAndCloseZoneForm(e) {
        e.preventDefault();
        const form = e.currentTarget;

        polygon.editor.stopDrawing();

        // save locally
        const zone = {};
        (new FormData(form)).forEach((value, key) => zone[key] = value);

        zone.points = polygon.geometry.getCoordinates()[0];
        zone.city_id = stateStore.city.id;
        saveZone(zone, polygon)

        hidePanel(map);
      };

      function saveZone(zone, polygon) {
        setPolygonState(polygon, 'pending');

        let method, url;
        if (zone.unsaved === 'true') {
          method = 'POST';
          url = '/zones';
        } else {
          method = 'PUT';
          url = `/zones/${zone.id}`;
        }

        const data = { zone: zone, authenticity_token: Verifier.authencityToken };

        fetch(url, { method: method,
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json' },
            body: JSON.stringify(data)})
          .then(response => response.json())
          .then(zone => {
            stateStore.zones[zone.id] = zone;
            polygon.properties.set('polyId', zone.id);

            setPolygonState(polygon, 'solid');
          });
      }

      function colorChanged(e) {
        const select = e.currentTarget;
        const color = select.selectedOptions[0].value;

        changeColor(polygon, color)
      }

      function destroyBtnClicked(e) {
        function destroyCompleted() {
          delete stateStore.zones[polyId];
          setPolygonState(polygon, 'solid');
          map.geoObjects.remove(polygon);
          hidePanel(map);
        }

        if (confirm('Подтвердите удаление')) {
          const polyId = polygon.properties.get('polyId')
          const zone = stateStore.zones[polyId];

          setPolygonState(polygon, 'pending');

          if (zone.unsaved) {
            destroyCompleted();
            return;
          }

          fetch(`/zones/${zone.id}`, {
            method: 'delete',
            headers: {
              'Accept': 'application/json',
              'Content-type': 'application/json' },
            body: JSON.stringify({ authenticity_token: Verifier.authencityToken }) })
          .then(response => { destroyCompleted() });
        }
      }

      showPanel(map);
    };

    function changeColor(polygon, color) {
      polygon.options.set('fillColor', color);
      polygon.options.set('strokeColor', color);
    }

    function setPolygonState(polygon, state) {
      switch (state) {
        case 'pending':
          polygon.options.set('strokeStyle', 'shortdot');
          polygon.options.set('fillOpacity', 0.2);
          polygon.options.set('strokeOpacity', 0.6);
          polygon.options.set('strokeWidth', 3);
          break;
        case 'intersection':
          polygon.options.set('strokeStyle', 'shortdot');
          polygon.options.set('fillOpacity', 0.0);
          polygon.options.set('strokeOpacity', 1);
          polygon.options.set('strokeWidth', 3);
          break;
        case 'solid':
          if (stateStore.getActivePolygon() === polygon) {
            polygon.options.set('strokeStyle', 'solid');
            polygon.options.set('fillOpacity', 0.6);
            polygon.options.set('strokeOpacity', 1);
            polygon.options.set('strokeWidth', 3);
          } else {
            polygon.options.set('strokeStyle', 'solid');
            polygon.options.set('fillOpacity', 0.6);
            polygon.options.set('strokeOpacity', 0.1);
            polygon.options.set('strokeWidth', 3);
          }
          break;
        default:
          throw 'Unexpacted polygon state'
      }
    }

    function showNewZoneBtn(map) {
      if (map.newZoneBtn) { map.newZoneBtn.options.set('visible', true) }
    }
    function hideNewZoneBtn(map) {
      if (map.newZoneBtn) { map.newZoneBtn.options.set('visible', false) }
    }

    function hidePanel(map) {
      if (map.panel) {
        map.panel._$control.css('display', 'none')
        map.panel.visible = false;
      }
      showNewZoneBtn(map)
    }
    function showPanel(map) {
      if (map.panel) {
        map.panel._$control.css('display', 'block')
        map.panel.visible = true;
      }
      hideNewZoneBtn(map)
    }

    function renderZoneForm(zone) {
      const colorOptions = Object.entries(stateStore.colors).map((e) => {
        return `<option value="${e[0]}" ${zone.color === e[0] && 'selected' }>${e[1]}</option>`
      }).join();
      const verifierOptions = stateStore.verifiers.map((e) => {
        return `<option value="${e[0]}" ${String(zone.verifier_id) === e[0] && 'selected' }>${e[1]}</option>`
      }).join();

      let str = `
      <form id="zone_form" name="zone_form">
        <input name="unsaved" value="${zone.unsaved}" type="hidden">
        <input name="id" value="${zone.id}" type="hidden">
        <p class="mb-1">
          <input name="name" value="${zone.name || ''}" class="form-control form-control-sm" placeholder="Название">
        </p>
        <p class="mb-1">
          <select id="color" name="color" class="form-select form-select-sm">${colorOptions}</select>
        </p>
        <p class="mb-1">
          <select id="verifier_id" name="verifier_id" class="form-select form-select-sm">${verifierOptions}</select>
        </p>
        <p class="d-flex justify-content-between mb-0">
          <input type="submit" value="Сохранить" class="btn btn-primary btn-sm">
          <button type="button" role="destroy" class="btn btn-danger btn-sm"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg></button>
        </p>
      </form>`;

      return (new DOMParser()).parseFromString(str, 'text/html').body.children[0];
    }

    function init() {
      const mapEl = document.querySelector('#zones_map');
      const center = [stateStore.city.lat, stateStore.city.lng];
      const map = new ymaps.Map(mapEl, {
        center: center,
        zoom: 13,
        controls: ['zoomControl', 'geolocationControl', 'searchControl']
      }, {
        searchControlProvider: 'yandex#search',
        searchControlNoSuggestPanel: true
      });


      if (isEditor) {
        const newZoneBtn = renderNewZoneBtn(map);
        map.newZoneBtn = newZoneBtn;
        newZoneBtn.events.add('click', function(e) { newZoneBtnClicked(map) });
      }

      Object.values(stateStore.zones).forEach(function(zone) {
        const polygon = createPolygon(map, zone);
        polygon.properties.set('polyId', zone.id);
        stateStore.zones[zone.id] = zone;
      });

      map.panel = new ymaps.Panel();
      map.controls.add(map.panel, { float: 'right' });

      stateStore.map = map;
    }
  }
})
