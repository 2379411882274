<script>
  import { onMount } from 'svelte';

  import BigNumber from "bignumber.js";

  import ProtocolIncDecButton from "components/protocol_inc_dec_button.svelte";
  import ProtocolDeltaField from "components/protocol_delta_field.svelte";

  const PRECISION = 6;
  const STEP = 1 / (10 ** PRECISION); // 0.000001

  export let volumeRanges;
  export let name;
  export let measurementAttrName;
  export let deltaAttrName;
  export let measurementLabel;
  export let deltaLabel;
  export let volumeInputId;
  export let measurement;
  export let delta;
  export let margin;
  export let deltaError;

  let volume;

  function volumeValue(el) {
    const measurement = parseFloat(el.value);
    const range = volumeRanges.find((r) => {
      return r['left'] <= measurement && r['right'] > measurement
    });

    if (range) {
      return range['volume']
    } else {
      return null
    }
  }

  onMount(() => {
    const volumeInput = document.getElementById(volumeInputId);

    volumeInput.addEventListener('change', (e) => {
      volume = volumeValue(e.currentTarget)

      if (!delta && !measurement) {
        let preDelta = getRandomDelta(margin);
        measurement = preDelta.plus(100.0).times(volume).div(100);
        delta = measurement.div(volume).times(100).minus(100.0);
      }
    })

    if (measurement && !delta) {
      volume = volumeValue(volumeInput);
      delta = (new BigNumber(measurement)).div(volume).times(100).minus(100.0);
    }
  })


  function getRandomDelta(margin) {
    const plusOrMinus = new BigNumber(Math.random() < 0.5 ? -1 : 1);

    return plusOrMinus.times(margin).times((Math.random() ** 2));
  }


  $: {
    if (measurement) {
       measurement = parseFloat((new BigNumber(measurement)).toFixed(PRECISION));
    }
  }
  $: isInvalid = delta < -margin || delta > margin;
</script>

<div class="row measurement-and-delta mb-3" class:is-invalid={isInvalid}>
  <div class="col-8 col-md-6 col-lg-4 col-xl-3">
    <label class="form-label" for="{name}_{measurementAttrName}">{measurementLabel}</label>

    <div class="d-flex">
      <div class="input-group">
        <ProtocolIncDecButton bind:measurement {volume} type="dec">
          <i data-feather="minus-circle" />
        </ProtocolIncDecButton>

        <input type="number" step={STEP} autocomplete="off" class="form-control"
          bind:value={measurement}
          id="{name}_{measurementAttrName}"
          name="{name}[{measurementAttrName}]">

        <ProtocolIncDecButton bind:measurement {volume} type="inc">
          <i data-feather="plus-circle" />
        </ProtocolIncDecButton>
      </div>
    </div>
  </div>

  <div class="col-4 col-md-3 col-lg-2 delta-group">
    <label for="{name}[{deltaAttrName}]" class="form-label">{@html deltaLabel || '&nbsp;'}</label>
    <ProtocolDeltaField name="{name}[{deltaAttrName}]"
       bind:delta
       bind:measurement
       {volume}
       {PRECISION}
       error="{deltaError}"/>
  </div>
</div>
