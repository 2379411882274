<script>
  const blankLabel = 'Отсутствует';

  export let name;
  export let label;
  export let value;

  const fieldName = name + "[seal]";
  const fieldId = name + "_seal";

  let blankChecked = value == blankLabel;

  function onClick(e) {
    if (e.currentTarget.checked) {
      value = blankLabel;
    }
  }

  function inputChanged() {
    if (value != blankLabel) {
      blankChecked = false;
    }
  }
</script>

<div class="d-flex justify-content-between">
  <label for="{fieldId}" class="form-label col">{label}</label>

  <div class="form-check col text-end">
    <input class="form-check-input float-none" type="checkbox" id="blank_seal"
      bind:checked={blankChecked}
      on:click={onClick}>
    <label class="form-check-label" for="blank_seal">{blankLabel}</label>
  </div>
</div>

<input autocomplete="off" class="form-control" type="text"
  id="{fieldId}" name="{fieldName}"
  bind:value="{value}" on:input={inputChanged}>
