<script>
  import BigNumber from "bignumber.js";

  export let PRECISION;
  export let name;
  export let delta;
  export let measurement;
  export let volume;
  export let error;

  let humanDelta;

  $: {
    if (measurement && volume) {
      delta = (new BigNumber(volume)).minus(measurement).dividedBy(measurement).times(100).toFixed(PRECISION);
    } else {
      delta = null;
    }
  }

  $: {
    if (delta) {
      humanDelta = (new BigNumber(delta)).toFixed(PRECISION).replace('.', ',')
    } else {
      humanDelta = '-';
    }
  }
</script>

<style>
  .delta { font-weight: 600 }
</style>

<input type="hidden" name="{name}" bind:value="{delta}">

<div class="delta">δ&nbsp;<span>{humanDelta}</span></div>
<div style="color: red">{error}</div>

