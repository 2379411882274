// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage";
import "channels";

const feather = require('feather-icons');

import Rails from "@rails/ujs";
Rails.start();

window.addEventListener("load", () => {
  feather.replace();
});

require("src/svelte_setup");
require("src/protocol_form");
require("src/zones");
require("src/bids");
require("src/users");
require("src/comments");

// Rails.start()
// Turbolinks.start()
ActiveStorage.start();

if (!window.Verifier) { window.Verifier = {} };
window.Verifier.authencityToken = document.querySelector('meta[name="csrf-token"]').content;

window.Version = "0.0.1";

