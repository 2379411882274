export default function (form) {
    let typeOfMeInput = form.querySelector('[name$="[type_of_me]"]');
    let stateRegisterNumberInput = form.querySelector('[name$="[state_register_number]"]');
    let meDisplayedValue = form.querySelector('#me_displayed_value');

    let listModalEl = form.querySelector('#me_list_modal');
    let listModal = bootstrap.Modal.getOrCreateInstance(listModalEl)

    let detailsModalEl = form.querySelector('#me_details_modal');
    let detailsModal = bootstrap.Modal.getOrCreateInstance(detailsModalEl)

    let filterEl = form.querySelector('#me_list-filter')
    let meListItems = listModalEl.querySelectorAll('.me_list-item');

    filterEl.addEventListener('keyup', itemsFiltering)
    filterEl.addEventListener('search', itemsFiltering)
    filterEl.addEventListener('blur', itemsFiltering)

    meListItems.forEach(item => {
        let detailsBtn = item.querySelector('.details');
        detailsBtn.addEventListener('click', detailsBtnClicked);
    });
    meListItems.forEach(item => {
        let itemContent = item.querySelector('.content');
        itemContent.addEventListener('click', meItemContentClicked);
    });

    function itemsFiltering(event) {
        let value = event.target.value.toLowerCase();
        meListItems.forEach(item => {
            let index = [
                item.dataset.type_of_me.toLowerCase(),
                item.dataset.state_register_number.toLowerCase(),
                item.dataset.designation.toLowerCase()
            ].join("\n")

            if (index.includes(value)) {
                item.classList.remove('-filtered');
            } else {
                item.classList.add('-filtered')
            }
        })
    }

    function detailsBtnClicked(event) {
        event.preventDefault();

        let itemEl = event.currentTarget.parentNode;

        let data = Object.assign({}, itemEl.dataset);
        let body = renderDetailsModalBody(data)

        detailsModalEl.querySelector('#me_details_label').innerText = data['state_register_number'];
        detailsModalEl.querySelector('.modal-body').innerHTML = body;

        listModal.hide()
        detailsModal.show();

        return false;
    }

    function renderDetailsModalBody(details) {
        let html = `<div class="me_details-content">`
        if (details.image) {
            html += `<img src="${details.image}" />`
        }
        if (details.designation) {
            html += `<h3>${details.designation}</h3>`
        }
        html += `
            <div class="attr">
                <p class="label">Наименование, тип СИ</p>
                <p class="value">${details.type_of_me || '–'}</p>
            </div>
            <div class="attr">
                <p class="label">Производитель</p>
                <p class="value">${details.manufacturer || '–'}</p>
            </div>
            <div class="attr">
                <p class="label">Методология</p>
                <p class="value">${details.methodology || '–'}</p>
            </div>
            <div class="attr">
                <p class="label">Интервал</p>
                <p class="value">
                    ${details.interval_cold || '–'}/${details.interval_hot || '–'}
                    лет (ХВС/ГВС)
                </p>
            </div>
        </div>`;

        return html;
    }

    function meItemContentClicked(event) {
        event.preventDefault();

        let item = event.currentTarget.parentNode;
        let typeOfMe = item.dataset.type_of_me;
        let stateRegisterNumber = item.dataset.state_register_number;

        typeOfMeInput.value = typeOfMe;
        stateRegisterNumberInput.value = stateRegisterNumber;
        meDisplayedValue.innerText = stateRegisterNumber + ' ' + typeOfMe;

        listModal.hide()

        meListItems.forEach(item => item.classList.remove('-selected'))
        item.classList.add('-selected')
    }
}
