function markCommentAsViewed(commentId) {
  const headers = { 'Accept': 'application/json', 'Content-type': 'application/json' };
  const body = JSON.stringify({ authenticity_token: Verifier.authencityToken });

  fetch(`/comments/${commentId}/view`, { method: 'post', headers: headers, body: body })
}

function observerCallback(entries) {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) { return }

    markCommentAsViewed(entry.target.dataset['id']);
    entry.target.classList.remove('-new')
  })
}

const observerOptions = { threshold: 0.8, delay: 3000 };
const observer = new window.IntersectionObserver(observerCallback, observerOptions)

const comments = document.querySelectorAll('.comment.-new')
comments.forEach((comment) => observer.observe(comment))
